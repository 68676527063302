


























import { Component, Vue } from 'vue-property-decorator';
import BoardBookmarks from "@/components/board/utilities/BoardBookmarks.vue";
import BoardShareInput from "@/components/board/BoardShareInput.vue";

@Component({
  components: {BoardShareInput, BoardBookmarks}
})
export default class StartCall extends Vue {

}
