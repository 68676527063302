



































































































































































































import {Component, Vue} from 'vue-property-decorator';
import BoardChatIcon from "@/components/navigation/BoardChatIcon.vue";

@Component({
  components: {BoardChatIcon},
})
export default class BoardShareInput extends Vue {
  open: boolean = false;
  activeTab: string | null = null;

  get board() {
    return this.$store.state.board;
  }

  get sharedResourcesCount() {
    // returning the number of shared files and bookmarks
    let totalCount = this.board.bookmarks.length + this.board.attachments.length;
    if (totalCount >= 1) {
      return totalCount;
    } else {
      return null;
    }
  }

  resetRoute() {
    this.$router.push({name: 'board-layout'});
  }

  closeShareBox() {
    this.open = false;
  }

  toggleChat() {
    this.closeShareBox();
    if (this.$route.name === 'chat') {
      this.resetRoute();
    } else {
      this.$router.push({name: 'chat'});
    }
  }

  toggleBoardInfo() {
    this.closeShareBox();
    if (this.$route.name === 'board-navigator') {
      this.resetRoute();
    } else {
      this.$router.push({name: 'board-navigator'});
    }
  }

  toggleShared() {
    this.closeShareBox();
    if (this.$route.name === 'board-shared') {
      this.resetRoute();
    } else {
      this.$router.push({name: 'board-shared'});
    }
  }
}
